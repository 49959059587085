.profile-block {
  width: 100%;
  background: #121417;
  border-radius: 0.625rem;
  padding: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__user {
    display: flex;
    align-items: center;
    background: #1f2124;
    border-radius: 0.625rem;
    padding-left: 1.25rem;

    &-img {
      min-width: 1.875rem;
      width: 1.875rem;
      height: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-block {
      color: #fff;
      text-transform: uppercase;
      padding: 0.625rem 1.25rem;
      background: #1f2124;
      border-radius: 0.625rem;
      text-align: left;
      width: 100%;
      & span {
        display: block;
        &:first-child {
          font-weight: 400;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
        }
        &:last-child {
          font-weight: 400;
          font-size: 1rem;
          text-transform: uppercase;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.625rem;
    padding-right: 1.25rem;
    background: #1f2124;
    border-radius: 0.625rem;
    margin-left: 0.625rem;
    &-icon {
      min-width: 1.5rem;
      width: 1.5rem;
      margin-right: 0.625rem;
    }
    &-title {
      font-weight: 400;
      font-size: 0.875rem;
      color: #fff;
    }
  }

  &__left {
    display: grid;
    grid-template-columns: 1.4fr 0.8fr 0.8fr;
    grid-column-gap: 0.625rem;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
