.how-to-start {
  margin-right: 1.25rem;
  width: 100%;
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    padding: 0.75rem 1.25rem;
    background: #1f2124;
    border-radius: 0.625rem;
    &__title {
      font-weight: 400;
      font-size: 1.375rem;
    }

    &__arrow {
      width: 0.4375rem;
      height: 0.75rem;
      & * {
        fill: #fff;
      }
    }
  }
}
