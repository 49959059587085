.app {
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  display: flex;

  &__header {
    width: 100%;
    position: relative;
    margin-top: 4rem;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &__main {
      position: relative;
      height: 100%;
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 1.25rem;
      gap: 1.25rem;
    }
  }

  &__user-tree {
    width: 20%;
  }
}

@import url('https://fonts.googleapis.com/css?family=Poppins:100,400,700,900');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  background: #000;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

html {
  font-size: 16px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
@mixin mq-font-by-max-width($fontSize, $maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    html {
      font-size: $fontSize;
    }
  }
}

@include mq-font-by-max-width(18px, 2600px);
@include mq-font-by-max-width(17px, 2300px);
@include mq-font-by-max-width(16px, 1920px);
@include mq-font-by-max-width(15px, 1750px);
@include mq-font-by-max-width(14px, 1600px);
@include mq-font-by-max-width(13px, 1440px);
@include mq-font-by-max-width(12px, 1366px);