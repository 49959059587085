.header-dropdown {
  position: absolute;
  top: 5rem;
  width: 18.75rem;
  border-radius: 0.625rem;
  overflow: hidden;
  background-color: #1F2124;

  &__upper {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__balance {
    text-align: right;
    & span {
      display: block;
      &:first-child {
        font-weight: 400;
        font-size: 0.75rem;
        color: #6D6D6D;
      }
      &:last-child {
        color: #fff;
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    max-width: 50%;
    &-img {
      width: 1.875rem;
      height: 1.875rem;
      min-width: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-name {
      color: #fff;
      text-transform: uppercase;
      margin-left: 0.625rem;
      width: 100%;
      & span {
        display: block;
        &:first-child {
          font-weight: 400;
          text-overflow: ellipsis;
          font-size: 0.875rem;
          overflow-x: hidden;
        }
        &:last-child {
          font-weight: 400;
          font-size: 0.75rem;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    padding: 0.625rem 1.25rem;
    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }
    &-title {
      font-weight: 400;
      font-size: 0.875rem;
      color: #fff;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }
}
