.notification {
  display: block;
  width: 100%;
  min-height: 2.875rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  animation: 0.7s ease init;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__img-error {
    position: absolute;
    width: 1.25rem;
    left: 0.8125rem;
    top: 1rem;
  }

  &__img-success {
    position: absolute;
    width: 1.25rem;
    left: 0.8125rem;
    top: 1rem;
  }

  &__img-default {
    position: absolute;
    width: 1.25rem;
    left: 0.8125rem;
    top: 1rem;
  }

  &__left {
    height: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  &__cross {
    display: block;
    width: 0.8125rem;
    margin-top: 1rem;
    margin-right: 0.8125rem;
    cursor: pointer;
  }

  &__text {
    flex-grow: 2;
    box-sizing: border-box;
    padding: 0.9375rem 0rem 0.9375rem 2.6875rem;
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    word-break: break-word;
  }

  &__wrapper {
    width: 100%;
    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
    display: flex;
    box-sizing: border-box;
    padding: 0.2rem 0;
    box-sizing: border-box;
  }

  &__wrapper_type {
    &_default {
      background: #121417; 
      border-radius: 0.625rem;
    }

    &_error {
      background: #121417;
      border-radius: 0.625rem;
    }

    &_success {
      background: #121417;
      border-radius: 0.625rem;
    }
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      // height: 4rem;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      // height: 4rem;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
