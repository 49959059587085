.phone-input {
  width: 100%;
  height: 2.5rem;
  padding: 0.625rem 0.3125rem 0.625rem 0.625rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.5);

  &__input {
    width: 60%;
    // color: #001040;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    outline: none;
    background: inherit;
    flex-grow: 2;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 1rem;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      border: 0;
      -webkit-text-fill-color: #001040;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: inherit;
    }
  }

  &__selector {
    width: 25%;
    min-width: 25%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
}
