.switcher {
  cursor: pointer;
  width: 100%;
  height: 1rem;
  border-radius: 0.5rem;
  background: #1f2124;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;
  position: relative;
  user-select: none;

  &__indicator {
    width: 1.3rem;
    height: 1.3rem;
    background: #121417;
    border: 0.125rem solid#1F2124;
    border-radius: 100%;
    transition: all 0.1s ease-in;
    position: absolute;
    left: 0%;
  }
  &_active &__indicator {
    left: calc(100% - 1.3rem);
    background: #ffffff;
    border: 0.125rem solid #e51525;
  }

  &_active {
    background: #e51525;
  }
}
