.dropdown {
  position: relative;

  &_isMobile &__items {
    top: 2.5rem;
  }

  &__action {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
      display: flex;
      width: 0.375rem;
      height: 1.375rem;
      border-radius: 50%;
    }

    &:hover {
      border-radius: 50%;
      background: #121417;

      svg {
        circle {
          fill: #fff;
        }
      }
    }
  }
  &__items {
    position: absolute;
    padding: 0.625rem 1.875rem 0.625rem 0.625rem;
    top: 4.125rem;
    right: 40%;
    background: #1f2124;
    border-radius: 0.625rem;
    width: 100%;
    min-width: 11.6875rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    &_isMobile {
      left: unset;
      right: 0;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;

    &:not(:last-child) {
      // margin-bottom: 1.25rem;
    }

    &-icon {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      min-height: 1.5rem;
      margin-right: 0.75rem;
    }

    &-title {
      font-size: 0.875rem;
      font-weight: 400;
      color: #fff;
    }
  }
}
