.my-debt-account {
  width: 100%;
  min-width: fit-content;
  &__header {
    font-weight: 400;
    font-size: 1.375rem;
    margin: 1.25rem 0 1.25rem 0.625rem;
  }
  &__filters {
    padding: 0 0.625rem;
  }
}
