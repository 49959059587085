.reports-table-providers {
  &_isMobile {
    padding: 0;
  }

  &__no-results {
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    padding: 1.25rem;
    background: #1f2124;
    border-radius: 0.625rem;
    width: 100%;
    text-align: center;
  }

  &__table {
    width: 100%;
    background: #121417;
    border-radius: 1.25rem;
    padding: 1.25rem;
    padding-top: 1.375rem;
    &-header {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 20%);
      font-weight: 400;
      font-size: 0.875rem;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1.0625rem;
    }
    &-head {
      &:first-child {
        text-align: left;
        margin-left: 0.625rem;
      }
    }
    &-body {
      width: 100%;
    }
  }

  &__header {
    font-weight: 400;
    font-size: 1.375rem;
    margin: 1.25rem 0.625rem;
  }
}
