.tabs {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  // gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.75rem;
    // width: 100%;
    padding: 0.625rem 2.1875rem 0.625rem 1.5625rem;
    // max-width: 10rem;
    // min-width: 120px;
    cursor: pointer;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: transparent;

    &-text {
      color: #fff;
      font-weight: 400;
      font-size: 0.875rem;
      text-wrap: nowrap;
    }

    &-icon {
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 0.625rem;
    }
    &_active {
      background: #121417;
    }
  }
}
