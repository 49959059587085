.days-filter {
  display: flex;
  $root: &;

  &__item {
    padding: 0.75rem 1.875rem;
    font-weight: 400;
    font-size: 0.875rem;
    background: #1f2124;
    border-radius: 0.625rem;
    cursor: pointer;
    max-height: 2.875rem;
    white-space: nowrap;
    #{$root}_isMobile & {
      width: 100%;
      background: #3b3b3d;
      font-size: 0.75rem;
      text-align: center;
    }
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
    &_active {
      background: #e51525;
    }

    &:hover {
      background: #e51525;
    }
  }
}
