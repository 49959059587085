.auth {
  background: #000;
  padding: 3.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__language-selector {
    width: 100%;
    max-width: 18.75rem;
  }

  &__content {
    background: #121417;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.375rem 0 4.6875rem 0;
    max-width: 38.75rem;
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;
    font-weight: 500;

    &-text {
      font-size: 1.875rem;
      text-transform: capitalize;
      margin-bottom: 4.375rem;
    }
    &-login {
      font-size: 1rem;
      margin-bottom: 1.25rem;
    }
  }

  &__form {
    width: 100%;
    max-width: 18.75rem;
  }

  &__other {
    background-color: #000;
    text-align: center;
    width: 100%;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    margin: 3.125rem 0;
    & span {
      display: inline-flex;
      padding: 1.1875rem;
    }
  }

  &__input-block {
    margin-bottom: 1.25rem;

    &__title {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.1875rem;
      color: #fff;
      margin-bottom: 0.125rem;
    }
  }

  &__button {
    height: 2.5rem;
  }
}
