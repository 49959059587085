.permissions-details-items-desktop {
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.6rem;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1f2124;
    padding: 0.875rem 1.25rem;
    border-radius: 0.6rem;
    max-height: 2.5rem;

    &_inner {
      padding-left: 1.563rem;
      background-color: transparent;
      cursor: unset;
    }

    &-switcher {
      width: 2.375rem;
      margin: 0 0.625rem 0 0.625rem;
      align-items: center;
      display: flex;

      &_noGap {
        margin-right: 0;
      }
    }

    &-container {
      display: flex;
      align-items: center;
    }

    &-title {
      color: #ffffff;
      text-transform: capitalize;
    }

    &-details {
      width: 1.563rem;
      height: 1.563rem;
      cursor: pointer;
    }

    &-dropdown {
      width: 0.75rem;
      height: 0.438rem;
      margin-right: 0.625rem;
      cursor: pointer;

      &_inner {
        * {
          cursor: auto;
        }
      }
    }
  }
}
