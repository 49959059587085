.header {
  z-index: 1;
  background: #000;
  // border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
  width: 100%;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  &__language-selector {
    margin-right: 0.625rem;
  }

  &_open &__user-arrow {
    transform: scale(-1);
  }

  &__whatsapp {
    margin-right: 1.25rem;
    cursor: pointer;
    &-icon {
      & svg {
        min-width: 2.25rem;
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  }

  &__user {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #1f2124;
    border-radius: 0.625rem;
    padding: 0.3125rem 0.3125rem 0.3125rem 0.9375rem;

    &-info {
      font-weight: 400;
      text-transform: uppercase;
      margin-right: 0.625rem;
      display: flex;
      flex-direction: column;
      text-align: right;

      &-id {
        font-size: 0.75rem;
      }

      &-login {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    &-img {
      width: 1.5rem;
      height: 1.5rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-arrow {
      width: 1.875rem;
      height: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__wrapper-notifies {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__notifies-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 2.625rem;
    height: 3.0625rem;
    border-radius: 0.625rem;
    margin: 0 0.625rem 0 0;
    background-color: #1f2124;
  }

  &__notifies-count {
    position: absolute;
    bottom: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 2px;
    min-width: 1.125rem;
    width: fit-content;
    height: 1.125rem;
    border-radius: 0.5625rem;
    background-color: #e51525;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #fff;
    box-sizing: border-box;
    padding: 2px 3px 0;
    cursor: pointer;
    user-select: none;
  }

  &__bell {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__notifies {
    position: absolute;
    top: 3.75rem;
  }
}
